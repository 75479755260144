<template>
  <div>
    <v-data-table
      :headers="cabecalhoPorTipoAcao()"
      :items="tipoAcao.acoes"
      :options.sync="pagination"
      :key="tableKey"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td style="width: 2%; text-align: center;">
              <v-menu bottom
                v-if="acaoCancelada(item)"
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="detalharAcao(item)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="verCancelamentoAcao(item)">
                    <v-list-item-action>
                      <v-icon>delete_sweep</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.ver_cancelamento', 2) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu bottom
                v-else
                origin="center center"
                transition="scale-transition"
                :close-on-content-click="fecharMenu"
                >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!canEdit || !acaoEmCadastro(item) || item.is_acao_outra_divisao"
                    @click="detalharAcao(item)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isTemplateVerbaVariavel(item) && canCrudAcao && false" @click.stop="copyItem(item)">
                  <v-list-item-action>
                    <v-icon>library_add</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.copiar', 2) }}</v-list-item-title>
                </v-list-item>
                  <v-list-item
                    v-if="acaoEmCadastro(item) && canEdit && !item.is_acao_outra_divisao"
                    @click="editarAcao(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="prepararJustificativaAprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAnalise(item)">
                    <v-list-item-action>
                      <v-icon>chat_bubble</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaReprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_down</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="acaoReprovado(item) || acaoEmAnalise(item)"
                    @click="abrirJustificativa(item)">
                    <v-list-item-action>
                      <v-icon>textsms</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.justificativa', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="acaoEmAnalise(item) && canEdit && item.solicitanteFluxo"
                    @click="confirmarExecutarAnalise(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarAcompanhamento(item)"
                    @click="abrirAcompanhamento(item)">
                    <v-list-item-action>
                      <v-icon>assignment_turned_in</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarApuracao(item)"
                    @click="abrirModalGerarNd(item)">
                    <v-list-item-action>
                      <v-icon>paid</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.ver_nota_debito', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarBotaoCancelarAcao(item)"
                    @click="confirmarCancelarAcao(item)">
                    <v-list-item-action>
                      <v-icon>cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ acaoAguardandoAprovacao(item) ? $tc('label.cancelar_fluxo', 1) : $tc('label.cancelar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarBotaoDownloadNotaFiscal(item)"
                    @click="downloadNotaFiscal(item)">
                    <v-list-item-action>
                      <v-icon>receipt</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.nota_fiscal', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-group
                      prepend-icon="receipt"
                      no-action
                      v-if="possuiNota(item)"
                      @click="fecharMenu = false"
                    >
                      <template v-slot:activator>
                        <v-list-item-content  @click="fecharMenu = false">
                          <v-list-item-title >{{$tc('label.nota_debito', 1)}}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-if="possuiNDPrevia(item)"
                        @click="imprimirNDPrevia(item)"
                        link
                      >
                        <v-list-item-icon>
                          <v-icon>print</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{$tc('label.previa', 1)}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="item.possui_recebimento"
                        @click="imprimirNota(item)"
                        link
                      >
                        <v-list-item-icon>
                          <v-icon>print</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{$tc('label.imprimir', 1)}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoAcao()" :key="col">
              <status
                v-if="col === 'status'"
                :status="item.status"
                :tooltip="item.status">
              </status>
              <span v-else-if="col === 'divisao'">
                <v-menu open-on-hover
                  offset-x
                  top
                  :nudge-width="300"
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{item.divisao}}
                    </span>
                  </template>
                  <v-card class="PlanejamentoContratoListTabela__Hier">
                    <v-card-title>
                      {{$tc('label.hierarquia', 1)}}
                    </v-card-title>
                    <v-card-text>
                      <v-treeview
                        open-all
                        open-on-click
                        :items="item.treeDivisao"
                        />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
              <span v-else-if="col === 'base_calculo'">
                {{ item.base_calculo ? $tc(`configuracao.BASE_CALCULO.${item.base_calculo}`) : '-' }}
              </span>
              <span v-else-if="col === 'num_nota_debito'">
                <v-btn v-if="exibirBotaoExibicaoMultiplasNds(item)" icon class="mx-0" @click.stop="abrirModalExibicaoMultiplasNds(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
                <span v-else-if="item.count_notas_debito <= countNotasExibicao">{{ item.num_nota_debito }}</span>
                <v-btn icon v-else class="mx-0" @click.native="visualizarNotasDebito(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </span>
              <span v-else-if="col === 'calculo_bonificacao'">
                {{ item.calculo_bonificacao ? $tc(`configuracao.CALCULO_BONIFICACAO.${item.calculo_bonificacao}`) : '-' }}
              </span>
              <span v-else-if="col === 'forma_bonificacao'">
                {{ item.forma_bonificacao ? $tc(`configuracao.BONIFICACAO.${item.forma_bonificacao}`) : '-' }}
              </span>
              <span v-else-if="col === 'calculo_apuracao'">
                {{ item.calculo_apuracao ? $tc(`configuracao.METAS_BENEFICIOS.${item.calculo_apuracao}`) : '-' }}
              </span>
              <span v-else-if="col === 'tipo_pagamento'">
                {{ item.tipo_pagamento ? $tc(`configuracao.TIPO_PAGAMENTO.${item.tipo_pagamento}`) : '-' }}
              </span>
              <span v-else-if="col === 'valor_maximo_nd'" style="white-space: nowrap;">
                {{ getMoney(item.valor_maximo_nd) }}
              </span>
              <span v-else-if="col === 'valor'" style="white-space: nowrap;">
                {{ isVerbaFixa(item) ? getMoney(item.valor) : '-' }}
              </span>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
      :id-acao="acaoSelecionada.id"
      :is-aprovacao="isAprovacao"
      :titulo-modal="tituloModal"
    >
    </planejamento-acao-justificativa>

    <apuracao-acao-lista-modal-gerar-nd
      ref="modalGerarNd"
      v-if="acaoModal"
      v-model="acaoModal"
      :abrir-modal="abrirModalND"
      :ocultaResumo="true"
      @ApuracaoAcaoListaModalGerarNd__fechar="fecharModalND">
    </apuracao-acao-lista-modal-gerar-nd>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'ACAO'">
    </acompanhamento>

    <acompanhamento-acao-apuracao
      ref="modalAcompanhamentoAcaoApuracao"
      :fluxo="statusPassos"
      :dados-modal-acompanhamento="dadosModalAcompanhamento"
      :lista-fluxo-apuracao="listStatusPassosApuracoes"
      :entidade="'ACAO'"
      @resetaCampos="resetaCampos">
    </acompanhamento-acao-apuracao>

    <detalhes-cancelamento
      ref="modalDetalhesCancelamento"
      :dados-usuario-cancelamento="dadosUsuarioCancelamento"
      :entidade="'ACAO'">
    </detalhes-cancelamento>

    <modal-nota-debito
    ref="modalNotaDebito">
    </modal-nota-debito>

    <modal-exibicao-multiplas-nds
      ref="ModalExibicaoMultiplasNds">
    </modal-exibicao-multiplas-nds>

    <planejamento-acao-verba-variavel-modal-nota-fiscal
        ref="modalNotaFiscal"
        :id-acao="idAcao"
        :executa-busca-acoes="true"
        @APROVAR_NOTA_FISCAL="aprovarNotaFiscal">
      </planejamento-acao-verba-variavel-modal-nota-fiscal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import ApuracaoAcaoListaModalGerarNd from '@/produto/spa/apuracao-acao-lista/ApuracaoAcaoListaModalGerarNd';
import ModalNotaDebito from '@/produto/spa/apuracao-acao-lista/ModalNotaDebito';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { buscarAcoes } from '../../../common/resources/planejamento/planejamento-acao-listagem';
import { getMoney } from '../../../common/functions/helpers';
import Status from '../../../shared-components/Status';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../shared-components/workflow/Acompanhamento';
import AcompanhamentoAcaoApuracao from '../../../shared-components/workflow/AcompanhamentoAcaoApuracao';
import DetalhesCancelamento from '../../../shared-components/DetalhesCancelamento';
import { skipLoading } from '../../../common/functions/loading';
import download from '../../../common/resources/downloads';
import getBasePath from '../../../common/functions/api-resource';
import { buscarPerfilPassoAprovador } from '../../../common/resources/workflow-acao';
import ModalExibicaoMultiplasNds from '../../../shared-components/ModalExibicaoMultiplasNdsDados';
import PlanejamentoAcaoVerbaVariavelModalNotaFiscal from '../form-verba-variavel/PlanejamentoAcaoVerbaVariavelModalNotaFiscal';

export default {
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
    DetalhesCancelamento,
    ApuracaoAcaoListaModalGerarNd,
    ModalNotaDebito,
    AcompanhamentoAcaoApuracao,
    ModalExibicaoMultiplasNds,
    PlanejamentoAcaoVerbaVariavelModalNotaFiscal,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    isTenantEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
    canCancelarFluxo() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CANCELAR_FLUXO_APROVACAO').length;
    },
    canCrudAcao() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ACAO_CRUD').length;
    },
  },
  props: {
    tipoAcao: Object,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.buscarAcoes();
      },
      deep: true,
    },
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      listStatusPassosApuracoes: [],
      apuracoes: [],
      indiceApuracao: 0,
      totalPage: 0,
      acaoModal: null,
      abrirModalND: false,
      pagination: {},
      lastPagination: {},
      dadosModalAcompanhamento: {},
      countNotasExibicao: 5,
      idsPassosAprovadores: [],
      tableKey: 0,
      cabecalhoFixo: [
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          width: '20%',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$tc('label.planejado', 1), value: 'planejado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.apurado', 1), value: 'apurado', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.periodo_apuracao', 1), value: 'periodo_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.fornecedor_pagador', 1), value: 'fornecedor_pagador', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.produto', 1), value: 'produto', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.tipo_pagamento', 1), value: 'tipo_pagamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false, width: '7%',
        },
        {
          text: this.$tc('label.grupo_fornecedor', 1), value: 'grupo_fornecedor', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.vigencia', 1), value: 'periodo_planejamento', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.base_calculo', 1), value: 'base_calculo', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.forma_bonificacao', 1), value: 'forma_bonificacao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.nome_solicitante', 1), value: 'nome_solicitante', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.id_acao_campanha', 1), value: 'id_acao', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.origem_verba', 1), value: 'origem_verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.tipo_verba', 1), value: 'tipo_verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.categoria_acao', 1), value: 'categoria_acao', sortable: false, width: '7%',
        },
        {
          text: this.$tc('label.valor_acao', 1), value: 'valor', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.valor_maximo_nd', 1), value: 'valor_maximo_nd', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.tipo_campanha', 1), value: 'cod_campanha', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.origem', 1), value: 'origem', sortable: false, width: '15%',
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.numero_notas_debito', 1), align: 'left', value: 'num_nota_debito', sortable: false, width: '15%', class: 'word-wrap-example',
        },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      isAprovacao: false,
      statusPassos: {},
      dadosUsuarioCancelamento: {},
      tituloModal: '',
      fecharMenu: true,
      idAcao: null,
    };
  },
  methods: {
    getMoney,
    isVerbaFixa(item) {
      return ['VALOR_FIXO', 'NOTA_FISCAL'].includes(item.calculo_bonificacao);
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    buscarAcoes() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      params.id_acao_tipo = this.tipoAcao.id;
      if (this.tipoAcao.idAgrupamento) {
        params.id_agrupamento = this.tipoAcao.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }
      skipLoading();
      buscarAcoes(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tipoAcao.acoes = [...res.data.resposta];
          this.tipoAcao.acoes.forEach((a) => {
            this.verificarUsuarioAprovador(a);
            this.verificarUsuarioSolicitante(a);
            this.buscarStatusFluxo(a);
            this.montaArvoreInvertidaDivisao(a);
            this.montaColunaNotaDebito(a);
          });
          setTimeout(() => this.$forceUpdate());
          this.tableKey += 1;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    montaArvoreInvertidaDivisao(acao) {
      const { hier } = acao;
      if (!hier || hier.length === 0) {
        return;
      }

      const itemHier = (name, children) => ({ name, children });

      const converteArvore = (children) => {
        children = children.map((a) => {
          const item = itemHier(a.nome, converteArvore(a.filhos));
          if (item.children.length === 0) {
            item.disabled = true;
          }
          return item;
        });
        return children;
      };

      const tree = hier.map((h) => {
        const base = itemHier(h.nome, h.filhos);
        base.children = converteArvore(base.children);
        return base;
      });

      acao.treeDivisao = tree;
    },
    montaColunaNotaDebito(item) {
      if (item.num_notas_debito) {
        item.num_nota_debito = item.num_notas_debito;
      }
    },
    abrirModalGerarNd(acao) {
      this.acaoModal = acao;
      this.acaoModal.cod_acao = acao.id;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    fecharModalND() {
      this.abrirModalND = false;
      setTimeout(() => { this.acaoModal = null; }, 2E2);
    },
    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoReprovado(acao) {
      const { status } = acao;
      return status === 'REPROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    acaoAguardandoAprovacao(acao) {
      const { status } = acao;
      return status === 'AGUARDANDO_APROVACAO';
    },
    acaoEmAnalise(acao) {
      const { status } = acao;
      return status === 'EM_ANALISE';
    },
    isTemplateVerbaVariavel(acao) {
      const { template } = acao;
      return template === 'VERBA_VARIAVEL';
    },
    isModalidadeNotaFiscal(acao) {
      return acao.calculo_bonificacao
        && acao.calculo_bonificacao === 'NOTA_FISCAL';
    },
    possuiNDPrevia(acao) {
      return ((acao.status_apuracao && !['APROVADO', 'CANCELADO'].includes(acao.status_apuracao)) || (!acao.status_apuracao))
          && (acao.possui_apuracao_em_andamento || acao.possui_nota_debito_previa_apuracao);
    },
    possuiNota(acao) {
      return !this.acaoReprovado(acao) && !this.acaoCancelada(acao)
        && (acao.possui_recebimento || acao.possui_apuracao_em_andamento || acao.possui_nota_debito_previa_apuracao);
    },
    editarAcao(acao) {
      let routeName = 'editarAcao';
      if (this.isTemplateVerbaVariavel(acao)) {
        routeName = 'editarAcaoVerbaVariavel';
      }
      this.$router.push({ name: routeName, params: { idAcao: acao.id, from: 'planejamento-acao' } });
    },
    detalharAcao(acao) {
      let routeName = 'detalharAcao';
      if (this.isTemplateVerbaVariavel(acao)) {
        routeName = 'detalharAcaoVerbaVariavel';
      }
      this.$router.push({ name: routeName, params: { idAcao: acao.id, from: 'planejamento-acao' } });
    },
    cabecalhoPorTipoAcao() {
      const { tipoAcao } = this;
      if (tipoAcao.cabecalho) return tipoAcao.cabecalho;

      const ordenacao = this.colunasPorTipoAcao(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          if (col === 'num_nota_debito') {
            cabecalho.push({
              text: this.tituloCampoDinamico(col),
              value: col,
              sortable: false,
              width: '10%',
            });
          } else {
            cabecalho.push({
              text: this.tituloCampoDinamico(col),
              value: col,
              sortable: false,
              width: '5%',
            });
          }
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoAcao.cabecalho = [colAcoes, ...cabecalho];

      return tipoAcao.cabecalho;
    },
    colunasPorTipoAcao() {
      return this.ordenacao;
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      // neste caso é um campo dinamico
      // TODO: implementar tratativa de formatador(?)
      if (this.ehCampoBoolean(item, nomeColuna)) {
        return item[nomeColuna] ? 'Sim' : 'Não';
      }

      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'valor'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    ehCampoBoolean(item, nomeColuna) {
      const valoresBoolean = [true, false];
      return valoresBoolean.indexOf(item[nomeColuna]) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosAcao.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosAcao.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    verificaSeUsuarioAprovadorNDComplementoInformacoesAprovar(acao) {
      const params = {
        idAcao: acao.id,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      this.workflowAcaoMagaluResource
        .verificarSeUsuarioAprovadorNDComplementoInformacoes(params)
        .then((res) => {
          this.condicionalWorkflow = res.data;
          if (this.condicionalWorkflow) {
            this.idAcao = acao.id;
            this.acaoSelecionada = acao;
            this.$refs.modalNotaFiscal.open();
          } else {
            this.abrirJustificativaAprovar(acao);
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    prepararJustificativaAprovar(item) {
      if (this.isTenantEpoca) {
        this.verificaSeUsuarioAprovadorNDComplementoInformacoesAprovar(item);
      } else {
        this.abrirJustificativaAprovar(item);
      }
    },
    abrirJustificativaAprovar(item) {
      this.tituloModal = '';
      const justObrigatoria = false;
      this.abrirJustificativaAvaliar(item, this.aprovar, justObrigatoria);
    },
    abrirJustificativaReprovar(item) {
      this.tituloModal = this.$t('label.reprovar');
      const justObrigatoria = true;
      this.abrirJustificativaAvaliar(item, this.reprovar, justObrigatoria);
    },
    abrirJustificativaAnalise(item) {
      const justObrigatoria = true;
      this.tituloModal = this.$t('label.enviar_analise');
      this.abrirJustificativaAvaliar(item, this.enviarAnalise, justObrigatoria);
    },
    abrirJustificativaAvaliar(item, acaoJustificativa, justObrigatoria) {
      this.justificativaObrigatoria = justObrigatoria;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.acaoSelecionada = item;
      this.isAprovacao = true;
      this.$refs.modalJustificativa.open();
    },
    aprovarNotaFiscal(numNotaFiscal) {
      this.acaoSelecionada.num_nota_fiscal = numNotaFiscal;
      const deveBuscarAcoes = true;
      this.aprovar('', deveBuscarAcoes);
    },
    aprovar(justificativa, deveBuscarAcoes = false) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          if (deveBuscarAcoes) {
            this.buscarAcoes();
          } else {
            this.buscarStatusFluxo(this.acaoSelecionada, true, () => {
              if (this.acaoSelecionada.fluxo.statusFluxo === 'APROVADO') {
                this.acaoSelecionada.status = 'APROVADO';
              }
              this.$forceUpdate();
              this.tableKey += 1;
            });
            this.acaoSelecionada.aprovadorFluxo = false;
            this.$forceUpdate();
            this.tableKey += 1;
          }
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.acaoSelecionada.status = 'REPROVADO';
          this.buscarStatusFluxo(this.acaoSelecionada);
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarCancelarAcao(acao) {
      if (acao.is_fluxo_paralelo && this.acaoAprovada(acao)) {
        this.$toast(this.$t('message.acao_nao_pode_cancelar'));
      } else {
        const content = this.acaoAprovada(acao)
          ? this.$tc('message.deseja_cancelar_acao_aprovada', 1, {
            status: this.$tc(`status_entidade.${acao.status.toLowerCase()}`),
          })
          : this.$t('message.deseja_cancelar_acao');
        if (this.acaoAguardandoAprovacao(acao)) {
          this.abrirJustificativaCancelar(acao);
        } else {
          setTimeout(() => this.$refs.confirmDialog
            .openWithParams(
              this.$t('title.atencao'),
              content,
              (ok) => {
                if (ok) {
                  this.cancelarAcao(acao, true);
                }
              },
            ));
        }
      }
    },
    abrirJustificativaCancelar(acao) {
      this.tituloModal = this.$t('title.cancelar_acao');
      this.justificativaSomenteLeitura = false;
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.cancelarAcao;
      this.isAprovacao = false;
      this.acaoSelecionada = acao;
      this.$refs.modalJustificativa.open();
    },
    cancelarAcao(acaoOuJustificativa, justificativaNaoObrigatorio = false) {
      const acao = justificativaNaoObrigatorio ? acaoOuJustificativa : this.acaoSelecionada;
      if (this.acaoEmCadastro(acao)) {
        this.cancelarAcaoEmCadastro(acao);
      } else if (this.acaoAprovada(acao)) {
        const justObrigatoria = true;
        this.abrirJustificativaAvaliar(
          acao,
          (justificativa) => this.cancelarAprovada(acao, justificativa),
          justObrigatoria,
        );
      } else {
        this.cancelarAcaoAguardandoAprovacao(acao, acaoOuJustificativa);
      }
    },
    cancelarAcaoEmCadastro(acao) {
      const idAcao = acao.id;
      this.planejamentoAcaoResource.cancelarAcao({ idAcao }, { idAcao })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.acao_cancelada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelarAcaoAguardandoAprovacao(acao, justificativa) {
      const idAcao = acao.id;
      this.workflowAcaoResource.cancelar({ idAcao }, { observacao: justificativa })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.fluxo_cancelado_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelarAprovada(acao, justificativa) {
      const idAcao = acao.id;
      this.planejamentoAcaoResource.cancelarAcao({ idAcao }, { justificativa })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.acao_cancelada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    confirmarExecutarAnalise(acao) {
      this.acaoSelecionada = acao;
      const content = this.$t('message.deseja_executar_analise_acao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise(acao);
            }
          },
        ));
    },
    enviarAnalise(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.acaoSelecionada.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.acaoSelecionada);
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(acao) {
      if (acao.status === 'AGUARDANDO_APROVACAO' && !acao.is_acao_outra_divisao) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              acao.aprovadorFluxo = true;
              this.$forceUpdate();
              this.tableKey += 1;
            }
          });
      }
    },
    verificarUsuarioSolicitante(acao) {
      if (!acao.is_acao_outra_divisao && (
        acao.status === 'EM_CADASTRO'
          || acao.status === 'EM_ANALISE'
          || acao.status === 'AGUARDANDO_APROVACAO'
          || acao.status === 'REPROVADO')) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.solicitante({ idAcao })
          .then((response) => {
            acao.solicitanteFluxo = response.data.usuarioHabilitado;
            this.$forceUpdate();
            this.tableKey += 1;
          });
      }
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.status({ idAcao })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(acao) {
      this.justificativa = acao.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.isAprovacao = false;
      this.$refs.modalJustificativa.open();
    },
    executarAnalise(acao) {
      const idAcao = this.acaoSelecionada.id;
      this.planejamentoAcaoResource.executarAnalise({ idAcao }, { idAcao })
        .then(() => {
          let routeName = 'editarAcao';
          if (this.isTemplateVerbaVariavel(acao)) {
            routeName = 'editarAcaoVerbaVariavel';
          }
          this.$router.push({ name: routeName, params: { idAcao, from: 'planejamento-acao' } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    mostrarApuracao(item) {
      return item.calculo_bonificacao === 'SELLIN' || item.calculo_bonificacao === 'SELLOUT' || item.calculo_bonificacao === 'FATURAMENTO_FORNECEDOR';
    },
    mostrarBotaoCancelarAcao(item) {
      return this.canEdit && !item.is_acao_outra_divisao
        && (this.acaoEmCadastro(item) || (this.canCancelarFluxo && this.acaoAguardandoAprovacao(item)));
    },
    mostrarBotaoDownloadNotaFiscal(acao) {
      return acao.num_nota_fiscal && this.isModalidadeNotaFiscal(acao);
    },
    downloadNotaFiscal(acao) {
      const file = acao.nome_fisico_arquivo;
      const fileName = `Nota fiscal - ${acao.num_nota_fiscal}.pdf`;
      const resource = download(this.$http);

      resource.downloadGet(this.uri(acao), {
        param: `?file=${file}&fileName=${fileName}`,
        url: 'download',
      })
        .then(() => {
          this.$toast(this.$t('message.download_efetuado'));
        })
        .catch(() => {
          this.$toast('Erro ao baixar. Tente novamente.');
        });
    },
    uri(acao) {
      const urlUpload = getBasePath('job', 'arquivo/acao');
      return `${urlUpload}/${acao.id}`;
    },
    abrirAcompanhamento(item) {
      this.dadosModalAcompanhamento = {
        idAcao: item.id_acao,
        modalidade: item.calculo_bonificacao,
      };
      const idAcao = item.id;
      if (!item.tem_fluxo) {
        this.$toast(this.$t('message.acao_aprovada_automatica'));
        return;
      }

      this.workflowAcaoResource.statusPasso({ idAcao }, { idAcao })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          if (this.statusPassos.passos) {
            const idsPassos = this.statusPassos.passos.map((passo) => passo.id.toString());
            const params = {
              idPassos: idsPassos,
            };
            buscarPerfilPassoAprovador(params, this.$resource).then((result) => {
              this.idsPassosAprovadores = result.body;
              this.statusPassos.passos.forEach((passo) => {
                passo.perfil = this.idsPassosAprovadores.find((e) => e.id_passo_execucao === passo.id).nom_perfil;
              });
            });
            const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
            if (passo) {
              passo.isProximoPasso = true;
            }
          }
          Promise.all([
            this.buscarApuracoes(idAcao),
            this.resetaCampos(),
          ]).then(() => {
            this.$refs.modalAcompanhamentoAcaoApuracao.open();
          });
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    buscarApuracoes(idAcao) {
      const param = { idAcao };
      const promise = this.apuracaoListagemResource.buscarApuracaoPorAcao(param)
        .then((res) => {
          this.apuracoes = res.data;
          if (this.apuracoes.length) {
            this.acompanhamentoApuracoes();
          }
        })
        .catch((err) => this.$error(this, err));
      return promise;
    },
    acompanhamentoApuracoes() {
      const promises = [];
      this.apuracoes.forEach((apuracao) => {
        const idApuracao = apuracao.id;
        if (!apuracao.temFluxo) {
          return;
        }
        const promise = this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
          .then((res) => {
            if (res.data.passos
                  && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
              res.data.passos.splice(1);
            }
            let statusPassosApuracoes = {};
            statusPassosApuracoes = res.data;
            if (apuracao.countNotasDebito && apuracao.numNotaDebito) {
              statusPassosApuracoes.countNotasDebito = apuracao.countNotasDebito;
              statusPassosApuracoes.numNotaDebito = apuracao.numNotaDebito;
              statusPassosApuracoes.modalidade = apuracao.modalidade;
              statusPassosApuracoes.idAcao = apuracao.idAcao;
            }
            if (statusPassosApuracoes.passos) {
              const passo = statusPassosApuracoes.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
              if (passo) {
                passo.isProximoPasso = true;
              }
            }
            this.indiceApuracao += 1;
            statusPassosApuracoes.indiceApuracao = this.indiceApuracao;
            this.listStatusPassosApuracoes.push(statusPassosApuracoes);
          })
          .catch((err) => {
            this.$toast(this.$t(err));
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    resetaCampos() {
      this.listStatusPassosApuracoes = [];
      this.indiceApuracao = 0;
    },
    verCancelamentoAcao(item) {
      const idAcao = item.id_acao;
      this.planejamentoAcaoListagemResource.buscaUsuarioCancelamentoAcao({ idAcao }, { idAcao })
        .then((res) => {
          this.dadosUsuarioCancelamento = res.body;
          this.$refs.modalDetalhesCancelamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    imprimirNDPrevia(item) {
      const idAcao = item.id;
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = download(this.$http);
      this.fecharMenu = true;
      if (item.possui_apuracao_em_andamento) {
        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito-apuracao-em-andamento/preview/${idAcao}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
      } else {
        if (item.calculo_bonificacao === 'VALOR_FIXO') {
          this.$toast(this.$t('message.exportacao_download'));
        } else {
          this.$toast(this.$t('message.calculo_nao_gerado'));
        }
        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito/preview/${idAcao}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
      }
    },
    imprimirNota(item) {
      const idRecebimento = item.id_recebimento,
        numeroND = item.num_nota_debito;
      const idAcao = item.id;
      this.fecharMenu = true;
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = download(this.$http);
      if (!idRecebimento) {
        this.imprimirNDPrevia(item);
        return;
      }

      resource.downloadGet(basePath, {
        param: `?numNota=${numeroND}`,
        url: `nota-debito-final/${idAcao}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    copyItem(item) {
      this.$router.push({ name: 'copiaAcaoVerbaVariavel', params: { id: item.id, acaoCopiada: item, copy: 'COPY' } });
    },
    exibirBotaoExibicaoMultiplasNds(item) {
      return item.qtd_nds && item.qtd_nds === 'MULTIPLAS' && item.lista_multiplas_nds && item.lista_multiplas_nds.length;
    },
    abrirModalExibicaoMultiplasNds(item) {
      this.$refs.ModalExibicaoMultiplasNds.open(item);
    },
  },
};
</script>
<style>
.PlanejamentoAcaoTabela__ColunaGrande {
  min-width: 150px;
}
.PlanejamentoAcaoTabela__ColunaMedia {
  min-width: 120px;
}
</style>
