<template lang="html">
  <v-dialog v-model="showModal" :max-width="listaFluxoApuracao.length? listaFluxoApuracao.length === 1 ? '1400' : '2000' : '800'">
        <v-card>
          <v-container class="bg-surface-variant">
            <v-row >
              <v-col cols="12" :sm="listaFluxoApuracao.length? listaFluxoApuracao.length === 1 ? '6' : '4' : '12'">
                <v-card-title class=" pr-0">
                  <span class="headline pr-0">
                    {{ titulo }}
                  </span>
                </v-card-title>
                <v-row class="pl-6">
                  <v-col cols="7">
                    <div class="pl-4">
                      <span>{{$t('label.modalidade')}}: {{ formataTextoModalidade(dadosModalAcompanhamento.modalidade)}}</span>
                    </div>
                    <div class="pl-4">
                      <span>{{$t('label.id_acao')}}: {{dadosModalAcompanhamento.idAcao}}</span>
                    </div>
                  </v-col>
                  <v-col cols="5" class="d-flex flex-column justify-center align-center">
                    <v-btn color="primary" small @click.native="exportarHistoricoAprovacao(dadosModalAcompanhamento.idAcao, entidadeAcao.toLowerCase())">
                      {{ $t('label.historico_de_aprovacoes') }}
                      <v-icon small>download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card-text>
                  <v-timeline dense class="mx-3 my-1 pt-0">
                    <v-timeline-item
                        :color="colorPalette[0]"
                        fill-dot
                        small
                        right
                        v-if="fluxo.cabecalhoFluxo">
                      <v-card class="elevation-2">
                        <v-card-title class="title historico_header px-3 py-2">{{$t('label.solicitado_por') + ' ' + fluxo.cabecalhoFluxo.usuarioSolicitante.nome + ' '
                          + fluxo.cabecalhoFluxo.usuarioSolicitante.sobrenome}}</v-card-title>
                        <v-card-text>
                          <v-row class="px-3 py-2">
                            <span><b class="pr-1">{{$t('label.descricao')}}:</b></span>
                            <span>{{fluxo.cabecalhoFluxo.descricao}}</span>
                          </v-row>
                          <v-row class="px-3 py-2">
                            <span><b class="pr-1">{{$tc('label.perfil', 1)}}:</b></span>
                            <span>{{fluxo.cabecalhoFluxo.usuarioSolicitante.nomePerfil}}</span>
                          </v-row>
                          <v-row class="px-3 py-2">
                            <span><b class="pr-1">{{$t('label.data_solicitacao')}}:</b></span>
                            <span>{{toDate(fluxo.cabecalhoFluxo.dtaSolicitacao)}}</span>
                          </v-row>
                          <v-row
                            v-if="fluxo && fluxo.cabecalhoFluxo && fluxo.cabecalhoFluxo.observacaoAcao"
                            class="px-3 py-2">
                            <span><b class="pr-1">{{$t('label.justificativa')}}:</b></span>
                            <span>{{ fluxo.cabecalhoFluxo.observacaoAcao }}</span>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                      v-for="(item, index) in fluxo.passos"
                      :color="styles(item.statusPasso)" fill-dot small right
                      v-if="exibePasso(item)" :key="index">
                      <template slot="icon">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="cursor: default;"></span>
                          </template>
                          <span>
                            {{$tc(`status_entidade.${item.statusPasso.toLowerCase()}`, 1)}}
                          </span>
                        </v-tooltip>
                      </template>
                      <acompanhamento-item
                          :item="item"
                          :cliente="fluxo.cliente"
                          :entidade="entidade"
                          :id-entidade="fluxo.idEntidade">
                      </acompanhamento-item>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-col>
              <v-col cols="12" :sm="listaFluxoApuracao.length === 1 ? '6': '8'" class="p-0">
                <v-col cols="12">
                  <v-row class="d-flex flex-nowrap overflow-x-auto">
                    <template v-for="(item, index) in listaFluxoApuracao" :step="2">
                      <v-col cols="12" :sm="listaFluxoApuracao.length === 1 ? 12 : 6" class="pb-0" v-if="index >= currentIndex && index < currentIndex + 2">
                        <v-card class="elevation-0">
                          <v-card-title class="d-flex align-center flex-column">
                            <span class="headline pl-5">{{ tituloApuracao }}</span>
                            <span class="pl-8">{{ `${$tc('label.parcial', 1)} #${item.indiceApuracao}` }}</span>
                          </v-card-title>
                          <v-row class="pl-4">
                            <v-col cols="7" class="pl-8 pr-0">
                              <div class="pl-4 pr-0">
                                <span>{{$t('label.nds')}}: {{formatarValoresNotaDebito(item.numNotaDebito)}}</span>
                                <v-btn
                                v-if="visualizaIconeModalNotaDebito(item.countNotasDebito)"
                                icon
                                class="mx-0"
                                @click.native="visualizarNotasDebito(item)" >
                                  <v-icon>visibility</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="5" class="d-flex flex-column justify-center align-center">
                              <v-btn color="primary" small @click.native="exportarHistoricoAprovacao(item.idEntidade, entidadeApuracao.toLowerCase())">
                                {{ $t('label.historico_de_aprovacoes') }}
                                <v-icon small>download</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-card-text class="pb-0">
                            <v-timeline dense class="mx-3 my-1 pt-0">
                              <v-timeline-item
                                  :color="colorPalette[0]"
                                  fill-dot
                                  small
                                  right
                                  v-if="item.cabecalhoFluxo">
                                <v-card class="elevation-2">
                                  <v-card-title class="title historico_header px-3 py-2">{{$t('label.solicitado_por') + ' ' + item.cabecalhoFluxo.usuarioSolicitante.nome + ' '
                                    + item.cabecalhoFluxo.usuarioSolicitante.sobrenome}}</v-card-title>
                                  <v-card-text>
                                    <v-row class="px-3 py-2">
                                      <span><b class="pr-1">{{$t('label.descricao')}}:</b></span>
                                      <span>{{item.cabecalhoFluxo.descricao}}</span>
                                    </v-row>
                                    <v-row class="px-3 py-2">
                                      <span><b class="pr-1">{{$tc('label.perfil', 1)}}:</b></span>
                                      <span>{{item.cabecalhoFluxo.usuarioSolicitante.nomePerfil}}</span>
                                    </v-row>
                                    <v-row class="px-3 py-2">
                                      <span><b class="pr-1">{{$t('label.data_solicitacao')}}:</b></span>
                                      <span>{{toDate(item.cabecalhoFluxo.dtaSolicitacao)}}</span>
                                    </v-row>
                                    <v-row
                                      v-if="item && item.cabecalhoFluxo &&
                                       item.cabecalhoFluxo.observacaoAcao"
                                      class="px-3 py-2">
                                      <span><b class="pr-1">{{$t('label.justificativa')}}:</b></span>
                                      <span>{{ item.cabecalhoFluxo.observacaoAcao }}</span>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-timeline-item>
                              <v-timeline-item
                                v-for="(item, index) in item.passos"
                                :color="styles(item.statusPasso)" fill-dot small right
                                v-if="exibePasso(item)" :key="index">
                                <template slot="icon">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on" style="cursor: default;"></span>
                                    </template>
                                    <span>
                                      {{$tc(`status_entidade.${item.statusPasso.toLowerCase()}`, 1)}}
                                    </span>
                                  </v-tooltip>
                                </template>
                                <acompanhamento-item
                                    :item="item"
                                    :cliente="item.cliente"
                                    :entidade="entidadeApuracao"
                                    :id-entidade="item.idEntidade">
                                </acompanhamento-item>
                              </v-timeline-item>
                            </v-timeline>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
                <v-col cols="12" class="d-flex justify-end pr-10 pt-0" v-if="listaFluxoApuracao.length">
                  <span>
                    {{ $t('label.total_de_apuracoes', {totalApuracao: listaFluxoApuracao.length}) }}
                  </span>
                </v-col>
                <v-col cols="12" v-if="listaFluxoApuracao.length && listaFluxoApuracao.length > 2">
                  <v-row class="d-flex align-center justify-center pl-15 pt-0">
                    <v-col cols="2" class="d-flex justify-end">
                      <v-btn fab @click="currentIndex = (currentIndex > 1) ? currentIndex - 2 : 0" class="mr-2">
                        <v-icon>arrow_back_ios_new</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-btn fab @click="currentIndex = (currentIndex < listaFluxoApuracao.length - 2) ? currentIndex + 2 : listaFluxoApuracao.length - 2" class="ml-2">
                        <v-icon>arrow_forward_ios</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" @click.native="fechar">{{ $t('label.fechar') }}</v-btn>
          </v-card-actions>
        </v-card>
        <modal-nota-debito
        ref="modalNotaDebito">
        </modal-nota-debito>
  </v-dialog>
</template>
<script>
import { removeEmptyProperties } from '@/produto/common/functions/helpers';
import exportacao from '@/produto/common/functions/exportacao';
import ModalNotaDebito from '@/produto/spa/apuracao-acao-lista/ModalNotaDebito';
import { countExportacaoHistoricoAprovacao } from '@/common/resources/magalu-relatorios';
import AcompanhamentoItem from './AcompanhamentoItem';

export default {
  name: 'AcompanhamentoAcaoApuracao',
  data() {
    return {
      showModal: false,
      countNotasExibicao: 4,
      tituloApuracao: `${this.$t('label.acompanhamento_fluxo')} - ${this.$t('label.apuracao_de_acao')}`,
      entidadeApuracao: 'APURACAO',
      entidadeAcao: 'ACAO',
      currentIndex: 2,
      colorPalette: [
        'teal accent-4', // Verde
        'red lighten-1', // Vermelho
        'orange darken-2', // Laranja
        'amber lighten-1', // Amarelo
        'blue-grey lighten-2', // Cinza
        'blue darken-1', // Azul
      ],
    };
  },
  props: {
    fluxo: Object,
    dadosModalAcompanhamento: Object,
    listaFluxoApuracao: Array,
    entidade: String,
  },
  components: {
    AcompanhamentoItem,
    ModalNotaDebito,
  },
  computed: {
    titulo() {
      const tituloBase = this.$t('label.acompanhamento_fluxo');
      return this.fluxo && this.fluxo.cabecalhoFluxo
        ? `${tituloBase}  - ${this.fluxo.cabecalhoFluxo.tipoFluxo}`
        : tituloBase;
    },
    isApuracao() {
      return this.entidade === 'APURACAO';
    },
  },
  methods: {
    open() {
      this.showModal = true;
      this.currentIndex = 0;
    },
    fechar() {
      this.$emit('resetaCampos');
      this.showModal = false;
    },
    toDate(data) {
      return this.moment(data, 'DD/MM/YYYY hh:mm:ss').format('DD-MM-YYYY HH:mm:ss');
    },
    exibePasso(passo) {
      return passo.statusPasso !== 'CONDICAO_NAO_ATENDIDA' && passo.statusPasso !== 'TRANSFERIDO';
    },
    styles(status) {
      let colorIndex = 0;
      if (status === 'ENCERRADO') {
        colorIndex = 5;
      } else if (status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (status === 'EM_FLUXO') {
        colorIndex = 4;
      } else if (status === 'CANCELADO') {
        colorIndex = 3;
      } else if (status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (status === 'REPROVADO') {
        colorIndex = 1;
      } else if (status === 'AGUARDANDO_EXECUCAO_METAS') {
        colorIndex = 6;
      } else if (status === 'AGUARDANDO_APURACAO') {
        colorIndex = 5;
      } else if (status === 'AGUARDANDO_PAGAMENTO') {
        colorIndex = 5;
      } else if (status === 'APURACAO_PREVIA') {
        colorIndex = 4;
      } else if (status === 'EM_ANALISE') {
        colorIndex = 3;
      } else if (status === 'EM_REIVINDICACAO') {
        colorIndex = 2;
      } else {
        colorIndex = 0;
      }

      return this.color || this.colorPalette[colorIndex];
    },
    visualizaIconeModalNotaDebito(countNotasDebito) {
      return countNotasDebito > this.countNotasExibicao;
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    formatarValoresNotaDebito(numNotaDebito) {
      const numNotas = numNotaDebito.split(',');
      if (numNotas.length > this.countNotasExibicao) {
        return `${numNotas.slice(0, 4).join(',')}...`;
      }
      return numNotaDebito;
    },
    formataTextoModalidade(modalidade) {
      let modalidadeFormatada = null;
      if (modalidade === 'SELLIN') {
        modalidadeFormatada = this.$t('label.sellin');
      } else if (modalidade === 'SELLOUT') {
        modalidadeFormatada = this.$t('label.sellout');
      } else if (modalidade === 'FATURAMENTO_FORNECEDOR') {
        modalidadeFormatada = this.$t('label.faturamento_fornecedor');
      } else if (modalidade === 'VALOR_FIXO') {
        modalidadeFormatada = this.$t('label.valor_fixo');
      }
      return modalidadeFormatada;
    },
    exportarHistoricoAprovacao(idEntidade, nomeEntidade) {
      const params = {
        idEntidade,
        nomeEntidade,
      };
      let quantidadeRegistros = 0;

      countExportacaoHistoricoAprovacao(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            if (nomeEntidade === this.entidadeAcao.toLowerCase()) {
              params.id_acao = idEntidade;
            } else {
              params.id_apuracao = idEntidade;
            }
            this.requestExportacao(params, 'historico_fluxo_aprovacao', 'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestExportacao(params, tipoRelatorio, modulo = 'api') {
      const filtroTratado = removeEmptyProperties(params);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
  mounted() {
  },
};
</script>
